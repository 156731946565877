import moosend from "mootracker";

export const Moosend = {
    init: () => {
        moosend.init("0eb3302f-7b51-485a-9abc-def4f31bfb81", false);
        moosend.trackPageView();
    },
    identify: (email: string) => {
        moosend.track("identify", { email });
    },
};

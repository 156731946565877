import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
        // scroll page to top on route change
        document.getElementsByTagName("body")[0].scroll(0, 0);
        window.scroll(0, 0);
    }, [location]);
};

import LogoAnimated from "components/svg/LogoAnimated";
import LogoAnimatedSurprised from "components/svg/LogoAnimatedSurprised";
import LogoAnimatedHappy from "components/svg/LogoAnimatedHappy";
import LogoAnimatedStressed from "components/svg/LogoAnimatedStressed";
import { ReactComponent as SiteName } from "assets/img/site-name.svg";
import { FrankyAnimation } from "types/FrankyAnimation";
import { Fragment } from "react";

export const FrankenstoriesLogo = (props: { type?: FrankyAnimation; hideText?: boolean }) => {
    const { type = "Normal", hideText } = props;

    const LogoUrl =
        type === "Normal"
            ? LogoAnimated
            : type === "Surprised"
            ? LogoAnimatedSurprised
            : type === "Stressed"
            ? LogoAnimatedStressed
            : LogoAnimatedHappy;

    return (
        <Fragment>
            <div className="vstack align-items-center">
                <div style={{ maxWidth: 220, width: "100%" }}>
                    <LogoUrl />
                </div>

                {!hideText && (
                    <div style={{ maxWidth: 290, width: "100%", fill: "white" }}>
                        <SiteName className="img-fluid" />
                    </div>
                )}
            </div>
        </Fragment>
    );
};

import clsx from "clsx";

type Props = {
    color?: "primary" | "white";
};

export const Spinner = (props: Props) => {
    const { color = "primary" } = props;
    return <span className={clsx(`fas fa-cog fa-spin text-${color}`)}></span>;
};

export const SpinnerBlock = (props: Props & { className?: string }) => {
    const { color, className } = props;

    return (
        <div className={clsx("d-flex align-items-center", className)}>
            <Spinner color={color} />
        </div>
    );
};

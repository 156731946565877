import { GeneratePaymentSessionCommand } from "commands/subscriptions/GeneratePaymentSessionCommand";
import { PurchaseResult } from "results/PurchaseResult";
import { useApiService } from "services/ApiService";

export const PaymentsApiService = () => {
    const { post } = useApiService({ controller: "/payments" });

    return {
        purchaseSubscription: (command: GeneratePaymentSessionCommand) => post<PurchaseResult>("/generate", command),
    };
};

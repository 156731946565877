import { Tooltip } from "bootstrap";
import { ComponentOptions } from "bootstrap/js/dist/base-component";
import { useEffect, useRef } from "react";

export const useTooltip = (props: {
    title?: string;
    placement?: "bottom" | "top" | "left" | "right";
    disabled?: boolean;
}) => {
    const { title, placement, disabled } = props;

    const tooltipRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (tooltipRef.current && title) {
            const options: ComponentOptions = {
                title,
                trigger: "hover",
            };

            if (placement) {
                options.placement = placement;
            }

            const t = Tooltip.getOrCreateInstance(tooltipRef.current, options);

            if (title && !disabled) {
                t.enable();
            } else {
                t.disable();
            }

            return () => {
                t.dispose();
            };
        }
    }, [disabled, placement, title]);

    return { tooltipRef };
};

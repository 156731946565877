import { IdCommand } from "commands/IdCommand";
import { AddStudentsCommand } from "commands/users/AddStudentsCommand";
import { CreateGroupCommand } from "commands/users/CreateGroupCommand";
import { JoinSchoolCommand } from "commands/users/JoinSchoolCommand";
import { RemoveStudentsFromGroupCommand } from "commands/users/RemoveStudentsFromGroupCommand";
import { UpdateGroupCommand } from "commands/users/UpdateGroupCommand";
import { UpdateStudentDetailsCommand } from "commands/users/UpdateStudentDetailsCommand";
import { CodeResult } from "results/CodeResult";
import { GetTeacherGroupRemoveStudentsResult } from "results/frankenstories/teachers/GetTeacherGroupRemoveStudentsResult";
import { GetTeacherGroupResult } from "results/frankenstories/teachers/GetTeacherGroupResult";
import { GetTeacherGroupsResult } from "results/frankenstories/teachers/GetTeacherGroupsResult";
import { GetTeacherPanelResult } from "results/frankenstories/teachers/GetTeacherPanelResult";
import { GetTeacherStudentResult } from "results/frankenstories/teachers/GetTeacherStudentResult";
import { GetTeacherStudentsResult } from "results/frankenstories/teachers/GetTeacherStudentsResult";
import { GetTeacherSubscriptionResult } from "results/frankenstories/teachers/GetTeacherSubscriptionResult";
import { GetTeacherSubscriptionsResult } from "results/frankenstories/teachers/GetTeacherSubscriptionsResult";
import { AddStudentsResult } from "results/users/AddStudentsResult";
import { CreateGroupResult } from "results/users/CreateGroupResult";
import { LoginResult } from "results/account/LoginResult";
import { useApiService } from "services/ApiService";
import { GlobalDataResult } from "results/account/GlobalDataResult";

export const TeachersApiService = () => {
    const { get, post } = useApiService({ controller: "/teachers" });

    return {
        getTeacherPanel: () => get<GetTeacherPanelResult>("/panel"),
        getGroups: () => get<GetTeacherGroupsResult>("/groups"),
        getGroup: (id: number) => get<GetTeacherGroupResult>(`/group/${id}`),
        getGroupRemoveStudents: (id: number) =>
            get<GetTeacherGroupRemoveStudentsResult>(`/group/remove-students/${id}`),
        getTeacherStudents: () => get<GetTeacherStudentsResult>("/students"),
        getTeacherStudent: (id: number) => get<GetTeacherStudentResult>(`/student/${id}`),
        joinSchool: (command: JoinSchoolCommand) => post<GlobalDataResult>("/join-school", command),
        leaveSchool: () => post<GlobalDataResult>("/leave-school"),
        regenerateGroupCode: (command: IdCommand) => post<CodeResult>("/group/regenerate-code", command),
        createGroup: (command: CreateGroupCommand) => post<CreateGroupResult>("/groups/create", command),
        updateGroup: (command: UpdateGroupCommand) => post("/groups/update", command),
        deleteGroup: (id: number) => post(`/groups/delete/${id}`),
        deleteStudent: (id: number) => post(`/students/delete/${id}`),
        removeStudentsFromGroup: (command: RemoveStudentsFromGroupCommand) => post("/groups/remove-students", command),
        updateStudentDetails: (command: UpdateStudentDetailsCommand) =>
            post("/students/update-student-details", command),
        getTeacherSubscriptions: () => get<GetTeacherSubscriptionsResult>("/subscriptions"),
        refreshSubscription: () => post("/refresh-subscription"),
        getTeacherSubscription: (id: number) => get<GetTeacherSubscriptionResult>(`/subscription/${id}`),
        addTeacherStudents: (command: AddStudentsCommand) => post<AddStudentsResult>("/students/add", command),
        completeTeacherIntroduction: () => post<LoginResult>("/complete-teacher-introduction"),
    };
};

import { CommandResult } from "results/CommandResult";
import { getToken } from "contexts/GlobalContext";

const api = "/api";

export const useApiService = (props: { controller: string }) => {
    const { controller } = props;

    const getUrl = (url: string) => `${api}${controller}${url}`;

    const getHeaders = (useJson = true) => {
        const h = new Headers();

        if (useJson) {
            h.append("Content-Type", "application/json");
        }

        const token = getToken();

        if (token) {
            h.append("Authorization", `Bearer ${token}`);
        }

        return h;
    };

    const submitForm = async <TResult extends unknown>(method: string, url: string, data?: unknown) => {
        const body = JSON.stringify(data);

        const x = await fetch(url, {
            method: method,
            body,
            headers: getHeaders(),
        });

        return await (x.json() as Promise<TResult>);
    };

    return {
        getUrl,
        getHeaders,
        get: async <T extends unknown>(url: string) => {
            const x = await fetch(getUrl(url), {
                method: "GET",
                headers: getHeaders(),
            });

            return await (x.json() as Promise<T>);
        },
        post: <T extends unknown>(url: string, data?: unknown) =>
            submitForm<CommandResult<T>>("POST", getUrl(url), data),
    };
};

import TagManager from "react-gtm-module";
import { GlobalContext } from "contexts/GlobalContext";
import { useContext } from "react";
import { buildDataLayer } from "utils/GtmUtils";

export const LogoutButton = (props: { className?: string }) => {
    const { className = "nav-link" } = props;

    const { logout, addNotification } = useContext(GlobalContext);

    return (
        <button
            onClick={() => {
                // log user out
                logout();

                // log gtm
                const dataLayer = buildDataLayer({
                    event: "logout",
                });

                TagManager.dataLayer({
                    dataLayer,
                });

                // notify user
                addNotification({
                    type: "success",
                    title: "Logout successful",
                });
            }}
            type="button"
            className={className}
        >
            <span>🚪</span>
            <span className="nav-link__text">Log out</span>
        </button>
    );
};

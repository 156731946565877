import "scss/app.scss";
import App from "App";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");

if (container) {
    const root = createRoot(container);

    root.render(<App />);
}

import clsx from "clsx";
import { Fragment } from "react";

export const IconText = (props: { icon: string; text?: string; textCssClass?: string; iconEnd?: boolean }) => {
    const { icon, text, textCssClass, iconEnd } = props;

    return (
        <Fragment>
            {!iconEnd && <span className={icon}></span>}
            {text && <span className={clsx(textCssClass)}>{text}</span>}
            {iconEnd && <span className={icon}></span>}
        </Fragment>
    );
};

/* eslint-disable camelcase */
import TagManager from "react-gtm-module";
import { LoginResult } from "results/account/LoginResult";
import { formatDate } from "utils/DateUtils";

export const dataTokenKey = "data";

// initialise user data
export const getUserData = () => {
    const dataString = localStorage.getItem(dataTokenKey);

    if (dataString) {
        const data = JSON.parse(dataString) as LoginResult;

        return data;
    } else {
        return undefined;
    }
};

export const buildDataLayer = (dataLayer: { [key: string]: unknown } = {}) => {
    const userData = getUserData();

    // only add user data if we find an id
    if (userData?.id) {
        // first add the user id
        dataLayer.uid = userData?.id;

        // account created date
        if (userData?.accountCreated) {
            const accountCreated = new Date(userData.accountCreated);

            const d = formatDate(accountCreated);

            dataLayer.accountCreated = d;
        }
    }

    return dataLayer;
};

export const gtmClearEcommerce = () => {
    TagManager.dataLayer({
        dataLayer: {
            ecommerce: null,
        },
    });
};

const checkoutLocalStorageKey = "checkout";

type CheckoutItem = {
    item_id: string;
    item_name: string;
    currency: string;
    item_brand: string;
    item_category: string;
    item_variant: string;
    price: number;
    quantity: number;
};

export const gtmBeginCheckout = (props: {
    itemId: string;
    itemName: string;
    currency: string;
    itemBrand: string;
    itemCategory: string;
    itemVariant: string;
    price: number;
    quantity: number;
}) => {
    gtmClearEcommerce();

    const { currency, itemBrand, itemCategory, itemId, itemName, itemVariant, price, quantity } = props;

    const item: CheckoutItem = {
        item_id: itemId,
        item_name: itemName,
        currency: currency,
        item_brand: itemBrand,
        item_category: itemCategory,
        item_variant: itemVariant,
        price: price,
        quantity: quantity,
    };

    const itemString = JSON.stringify(item);

    localStorage.setItem(checkoutLocalStorageKey, itemString);

    TagManager.dataLayer({
        dataLayer: {
            event: "begin_checkout",
            ecommerce: {
                items: [item],
            },
        },
    });
};

export const gtmPurchase = (props: { userType: "school" | "teacher" }) => {
    const { userType } = props;

    gtmClearEcommerce();

    const itemString = localStorage.getItem(checkoutLocalStorageKey);

    if (itemString) {
        const item = JSON.parse(itemString) as CheckoutItem;
        const { currency, item_brand, item_category, item_id, item_name, item_variant, price, quantity } = item;

        const total = price * quantity;
        const accountType = userType === "school" ? "free" : "paid";

        if (item) {
            // send event
            TagManager.dataLayer({
                dataLayer: {
                    event: "purchase",
                    userType,
                    accountType,
                    ecommerce: {
                        transaction_id: item_id,
                        value: total,
                        currency,
                        items: [
                            {
                                price,
                                quantity,
                                currency,
                                item_id: item_id,
                                item_name: item_name,
                                item_brand: item_brand,
                                item_category: item_category,
                                item_variant: item_variant,
                            },
                        ],
                    },
                },
            });

            // remove item
            localStorage.removeItem(checkoutLocalStorageKey);
        }
    }
};

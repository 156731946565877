import { FrankyAnimation } from "types/FrankyAnimation";
import { FrankenstoriesLogo } from "components/FrankenstoriesLogo";
import { useTooltip } from "hooks/useTooltip";

export const Promo = (props: { type?: FrankyAnimation; hideSubtitle?: boolean }) => {
    const { type = "Normal", hideSubtitle } = props;
    const { tooltipRef } = useTooltip({ title: "Franky" });

    return (
        <div className="vstack gap-2 text-center" ref={tooltipRef}>
            <h1 className="w-100">
                <FrankenstoriesLogo type={type} />
            </h1>

            {!hideSubtitle && (
                <div className="h4 text-white fw-light align-items-center text-nowrap">
                    <div>Let&apos;s stitch a story together</div>
                </div>
            )}
        </div>
    );
};

import { GetPresetsBrowseResult } from "results/frankenstories/presets/GetPresetsBrowseResult";
import { GetPresetsSearchResult } from "results/frankenstories/presets/GetPresetsSearchResult";
import { GetPresetsTagsResult } from "results/frankenstories/presets/GetPresetsTagsResult";
import { GetPresetsTopicResult } from "results/frankenstories/presets/GetPresetsTopicResult";
import { useApiService } from "services/ApiService";

export const PresetsApiService = () => {
    const { get } = useApiService({ controller: "/presets" });

    return {
        getPresetsBrowse: () => get<GetPresetsBrowseResult>("/browse"),
        getPresetsTopic: (friendlyUrl: string) => get<GetPresetsTopicResult>(`/topic/${friendlyUrl}`),
        getPresetsTags: () => get<GetPresetsTagsResult>("/tags"),
        getPresetSearch: () => get<GetPresetsSearchResult>("/search"),
    };
};

import { AuthorisedReportIdQuery } from "queries/reporting/AuthorisedReportIdQuery";
import { AuthorisedReportQuery } from "queries/reporting/AuthorisedReportQuery";
import { GetTeacherEverythingReportResult } from "results/frankenstories/teachers/GetTeacherEverythingReportResult";
import { GetTeacherGameReportResult } from "results/frankenstories/teachers/GetTeacherGameReportResult";
import { GetTeacherGameRevisedStoriesReportResult } from "results/frankenstories/teachers/GetTeacherGameRevisedStoriesReportResult";
import { GetTeacherGroupReportResult } from "results/frankenstories/teachers/GetTeacherGroupReportResult";
import { GetTeacherReportingResult } from "results/frankenstories/teachers/GetTeacherReportingResult";
import { GetTeacherStudentReportResult } from "results/frankenstories/teachers/GetTeacherStudentReportResult";
import { useApiService } from "services/ApiService";

export const ReportingApiService = () => {
    const { get } = useApiService({ controller: "/reporting" });

    return {
        getTeacherReporting: () => get<GetTeacherReportingResult>("/reporting"),
        getTeacherEverythingReporting: (query: AuthorisedReportQuery) =>
            get<GetTeacherEverythingReportResult>(
                `/reporting/everything?timezone=${encodeURIComponent(query.timezone)}`,
            ),
        getTeacherGameReport: (query: AuthorisedReportIdQuery) =>
            get<GetTeacherGameReportResult>(
                `/reporting/game/${query.id}?timezone=${encodeURIComponent(query.timezone)}`,
            ),
        getTeacherGameRevisedStoriesReport: (query: AuthorisedReportIdQuery) =>
            get<GetTeacherGameRevisedStoriesReportResult>(
                `/reporting/game-revised-stories/${query.id}?timezone=${encodeURIComponent(query.timezone)}`,
            ),
        getTeacherStudentReport: (query: AuthorisedReportIdQuery) =>
            get<GetTeacherStudentReportResult>(
                `/reporting/student/${query.id}?timezone=${encodeURIComponent(query.timezone)}`,
            ),
        getTeacherGroupReport: (query: AuthorisedReportIdQuery) =>
            get<GetTeacherGroupReportResult>(
                `/reporting/group/${query.id}?timezone=${encodeURIComponent(query.timezone)}`,
            ),
    };
};

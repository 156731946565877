import { RouteList } from "Routes";
import { useTooltip } from "hooks/useTooltip";
import { Link } from "react-router-dom";

export const ProBadge = () => {
    const { tooltipRef } = useTooltip({ title: "Available in Frankenstories Pro - Register to try for free" });

    return (
        <div ref={tooltipRef}>
            <Link
                target="_blank"
                to={RouteList.SubscriptionsAbout}
                className="btn btn-sm btn-success rounded-pill btn-icon text-white text-uppercase"
            >
                <span>🥇</span>
                <span>Pro</span>
            </Link>
        </div>
    );
};

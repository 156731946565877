import { ReactNode } from "react";
import { ModalDialog } from "components/modals/ModalDialog";

export type WindowConfirmProps = {
    confirmText?: string;
    confirmBtnClass?: string;
    confirmDescription?: ReactNode;
    confirmIcon?: string;
    secondaryText?: string;
    secondaryBtnClass?: string;
    secondaryIcon?: string;
    onSecondaryConfirm?: () => void;
    onConfirm?: () => void;
    loading?: boolean;
    successMessage?: string;
    showPro?: boolean;
    disabled?: boolean;
    title?: string;
};

export const WindowConfirm = (props: WindowConfirmProps) => {
    const {
        confirmText,
        confirmDescription,
        onConfirm,
        confirmIcon,
        loading,
        successMessage,
        confirmBtnClass = "btn-danger",
        showPro,
        disabled,
        title = "Are you sure?",
        secondaryBtnClass,
        secondaryText,
        secondaryIcon,
        onSecondaryConfirm,
    } = props;

    return (
        <ModalDialog
            id="window-confirm"
            title={title}
            onConfirm={onConfirm}
            confirmBtnText={confirmText}
            confirmBtnClass={confirmBtnClass}
            confirmBtnIcon={confirmIcon}
            loading={loading}
            successMessage={successMessage}
            showPro={showPro}
            disabled={disabled}
            secondaryBtnClass={secondaryBtnClass}
            secondaryIcon={secondaryIcon}
            secondaryText={secondaryText}
            onSecondaryConfirm={onSecondaryConfirm}
        >
            {confirmDescription}
        </ModalDialog>
    );
};

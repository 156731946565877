import { ReactNode } from "react";
import { Promo } from "components/Promo";
import { RouteList } from "Routes";
import { Link } from "react-router-dom";
import { FrankyAnimation } from "types/FrankyAnimation";

export const Decision = (props: {
    text: ReactNode;
    description?: ReactNode;
    actionElement?: ReactNode;
    showReloadButton?: boolean;
    hideGoHome?: boolean;
    animationType?: FrankyAnimation;
}) => {
    const { text, actionElement, showReloadButton, description, hideGoHome, animationType = "Surprised" } = props;

    return (
        <div className="vstack gap-4 container-mini">
            <Promo type={animationType} />

            <div className="vstack gap-3">
                <div className="card card-body text-center vstack gap-2">
                    <div className="h4 text-secondary">{text}</div>
                    {description}
                </div>

                <div className="d-flex justify-content-center gap-2 align-items-center flex-column flex-lg-row">
                    {actionElement}

                    {showReloadButton && (
                        <button type="button" className="btn btn-primary" onClick={() => window.location.reload()}>
                            Reload
                        </button>
                    )}

                    {!hideGoHome && (
                        <Link to={RouteList.Home} className="btn btn-dark">
                            Go home
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

import { CodeCommand } from "commands/CodeCommand";
import { GameRoundCommand } from "commands/frankenstories/GameRoundCommand";
import { GameSessionIdCommand } from "commands/frankenstories/GameSessionIdCommand";
import { JoinGameCommand } from "commands/frankenstories/JoinGameCommand";
import { ReactCommand } from "commands/frankenstories/ReactCommand";
import { SubmitReplyCommand } from "commands/frankenstories/SubmitReplyCommand";
import { SubmitRevisedStoryCommand } from "commands/frankenstories/SubmitRevisedStoryCommand";
import { SubmitVoteCommand } from "commands/frankenstories/SubmitVoteCommand";
import { UpdateNameCommand } from "commands/frankenstories/UpdateNameCommand";
import { WaveCommand } from "commands/frankenstories/WaveCommand";
import { sessionId } from "contexts/GlobalContext";
import { ConnectToGameResult } from "results/frankenstories/ConnectToGameResult";
import { GetGameStateResult } from "results/frankenstories/GetGameStateResult";
import { GetJoinGameResult } from "results/frankenstories/GetJoinGameResult";
import { useApiService } from "services/ApiService";

export const GameplayApiService = () => {
    const { get, post } = useApiService({ controller: "/gameplay" });

    return {
        getGameState: (code: string) => get<GetGameStateResult>(`/game-state/${code}`),
        getJoin: (code: string) => get<GetJoinGameResult>(`/join/${code}/${sessionId}`),
        joinGame: (command: JoinGameCommand) => post<GetGameStateResult>("/join", { ...command, sessionId }),
        preconnect: (command: CodeCommand) => post<ConnectToGameResult>("/preconnect", { ...command, sessionId }),
        resetPlayerConnection: () => post("/reset-player-connection", { sessionId }),
        updatePlayerName: (command: UpdateNameCommand) => post("/update-player-name", { ...command, sessionId }),
        changeAvatar: (command: GameSessionIdCommand) => post("/change-avatar", { ...command, sessionId }),
        submitReply: (command: SubmitReplyCommand) => post("/submit-reply", { ...command, sessionId }),
        completeWriting: (command: SubmitReplyCommand) => post("/complete-writing", { ...command, sessionId }),
        completeAwarding: (command: GameRoundCommand) => post("/complete-awarding", { ...command, sessionId }),
        wave: (command: WaveCommand) => post("/wave", { ...command, fromSessionId: sessionId }),
        vote: (command: SubmitVoteCommand) => post("/vote", { ...command, sessionId }),
        submitReaction: (command: ReactCommand) => post("/react", { ...command, sessionId }),
        leaveGame: (command: CodeCommand) => post("/leave", { ...command, sessionId }),
        submitRevisedStory: (command: SubmitRevisedStoryCommand) =>
            post("/submit-revised-story", { ...command, sessionId }),
    };
};

import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Redirects, RouteTable } from "Routes";
import { Fragment, Suspense, useContext, useEffect } from "react";
import { GlobalContext } from "contexts/GlobalContext";
import { ToastContainer } from "components/ToastContainer";
import { Decision } from "components/Decision";
import { WindowConfirm } from "components/WindowConfirm";
import { Page } from "pages/Page";
import { Cat } from "components/characters/Cat";

export const Routes = () => {
    const history = useHistory();
    const location = useLocation();

    const { error, setError, windowConfirm, clearNotifications } = useContext(GlobalContext);

    // redirects
    useEffect(() => {
        const pathName = location.pathname.toLowerCase();

        const hasRedirect = Redirects.has(pathName);

        if (hasRedirect) {
            const redirect = Redirects.get(pathName);

            if (redirect) {
                window.location.href = redirect;
            }
        }
    }, [history, location]);

    useEffect(() => {
        history.listen(() => {
            // clear errors on page change
            setError(undefined);

            // reset notifications
            clearNotifications();
        });
    }, [clearNotifications, history, setError]);

    return (
        <Fragment>
            {error ? (
                <Page
                    authType="Public"
                    canonical={window.location.href}
                    description="Server error"
                    title="Server error"
                >
                    <div>
                        <Decision
                            text={error}
                            description="If the problem persists try closing all your browser windows"
                            showReloadButton={true}
                        />
                    </div>
                </Page>
            ) : (
                <Suspense
                    fallback={
                        <div className="pt-5">
                            <div className="d-flex justify-content-center align-items-center">
                                <Cat />
                            </div>
                        </div>
                    }
                >
                    <Switch>
                        {RouteTable.map(({ path, Component }) => (
                            <Route exact path={path} key={path}>
                                {({ match }) => <Component {...match?.params} key={window.location.search} />}
                            </Route>
                        ))}
                    </Switch>
                </Suspense>
            )}

            <WindowConfirm {...windowConfirm} />

            <ToastContainer />
        </Fragment>
    );
};
